import classNames from 'classnames'
import React from 'react'
import NameEmailForm from 'components/NameEmailForm'
import paths from 'constants/paths'
import notifications from 'libs/notifications'

type Props = {
  form?: React.Component
  resource: unknown
}

export default function GatedResourceForm({ form = NameEmailForm, resource, ...props }: Props) {
  // eslint-disable-next-line no-unused-vars
  async function submit(values) {
    notifications.notifyAndRedirect({
      message: 'Thanks for the request—we’ll be in touch',
      to: paths.HOME,
    })
  }

  function handleSubmit(values) {
    return submit(values)
  }

  const Form = form

  return (
    <Form {...props} buttonCopy="Submit" className="GatedResourceForm" onSubmit={handleSubmit} />
  )
}
