import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import GatedResourceForm from 'components/GatedResourceForm'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './GatedResource.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = {
  children: React.ReactNode
  entity: string
  form: React.ReactNode
  formAlternate: React.ReactNode
  formTitle: string
  hideDisclaimer?: boolean
  resource: string
  sidebar?: React.ReactNode
  subtext: string
  title: string
} & Component

export default function GatedResource({
  children,
  className,
  entity,
  form,
  formAlternate,
  formTitle,
  hideDisclaimer,
  resource,
  sidebar,
  subtext,
  title,
}: Props) {
  useEventPageVisited('Resource', { resource })

  return (
    <Layout
      footer={false}
      header={false}
      className={classNames('GatedResource', styles.this, className)}>
      <Head title={resource} />
      <div className={styles.inner}>
        <View className={styles.main}>
          <div className={styles['main--inner']}>
            <Text color="white" element="div" className={styles.resource}>
              <Container flush>
                <Row size="medium">
                  <Row size="small">
                    <Text size="xxxlarge" weight="semibold">
                      {title}
                    </Text>
                  </Row>
                  <Text element="p" size="xlarge" weight="medium">
                    {subtext}
                  </Text>
                </Row>
                <Text element="div" size="xlarge">
                  {children}
                </Text>
              </Container>
            </Text>
            <Link to={paths.HOME}>
              <LogoSvg aria-label="Bold" className={styles.logo} />
            </Link>
          </div>
        </View>
        <View className={styles.sidebar}>
          <Container flush size="small">
            {sidebar || (
              <>
                <Row size="medium">
                  <Text element="h2" size="xlarge" weight="semibold">
                    {formTitle ?? `Yes! Send me the ${entity}.`}
                  </Text>
                </Row>
                <Row flush={hideDisclaimer} size="large">
                  {formAlternate || <GatedResourceForm form={form} resource={resource} />}
                </Row>
                {!hideDisclaimer && (
                  <Text element="p" size="small">
                    By entering your information, you agree to be added to the Bold email list. You
                    may unsubscribe at any time.
                  </Text>
                )}
              </>
            )}
          </Container>
        </View>
      </div>
    </Layout>
  )
}
